// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import '../../../../node_modules/react-image-lightbox/style.css';

import {scroller} from "react-scroll";
import Slider from "react-slick";
import FeatherIcon from 'feather-icons-react';

// import images
import aire1 from '../../../assets/images/multiservicios/climatizacion/aire1.jpg';
import aire2 from '../../../assets/images/multiservicios/climatizacion/aire2.jpg';
import aire3 from '../../../assets/images/multiservicios/climatizacion/aire3.jpg';
import calefaccion1 from '../../../assets/images/multiservicios/climatizacion/calefaccion1.jpg';
import calefaccion2 from '../../../assets/images/multiservicios/climatizacion/calefaccion2.jpg';
import calefaccion3 from '../../../assets/images/multiservicios/climatizacion/calefaccion3.jpg';
import eficiencia1 from '../../../assets/images/multiservicios/climatizacion/eficiencia1.jpg';
import eficiencia2 from '../../../assets/images/multiservicios/climatizacion/eficiencia2.jpg';
import eficiencia3 from '../../../assets/images/multiservicios/climatizacion/eficiencia3.jpg';
import purificacion1 from '../../../assets/images/multiservicios/climatizacion/purificacion1.jpg';
import purificacion2 from '../../../assets/images/multiservicios/climatizacion/purificacion2.jpg';
import purificacion3 from '../../../assets/images/multiservicios/climatizacion/purificacion3.jpg';

class ClimatizacionDetailSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            type: '',
            items: {
                aire: {id: 'aire-acondicionado', images: [aire1, aire2, aire3]},
                calefaccion: {id: 'calefaccion', images: [calefaccion1, calefaccion2, calefaccion3]},
                eficiencia: {id: 'eficiencia-energetica', images: [eficiencia1, eficiencia2, eficiencia3]},
                purificacion: {id: 'purificacion-de-aire', images: [purificacion1, purificacion2, purificacion3]},
            }
        }
    }

    componentDidMount() {
        window.addEventListener('hashchange', this.hashChanged, false);
        if(window.location.hash) {
            this.hashChanged();
        }

        Object.keys(this.state.items).map((key, index) => {
            const parentId = document.getElementById(this.state.items[key].id);
            const e1 = parentId.getElementsByClassName("slick-slide");
            this.state.items[key].images.map((item, i) => {
                e1[i].style.backgroundImage = `url(${item})`;
                e1[i].style.backgroundRepeat = 'no-repeat';
                e1[i].style.backgroundSize = 'cover';
                e1[i].style.backgroundPositionY = 'center';
                e1[i].style.backgroundPositionX = 'center';
                e1[i].style.borderRadius = '6px';
            });
        });
    }

    hashChanged () {
        const divId = window.location.hash.substring(1);
        scroller.scrollTo(divId, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -120
        });
    }

    render() {
        const settings = {
            autoplay: true,
            infinite: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade : true,
            draggable : true,
            pauseOnHover: true,
        };
        return (
            <React.Fragment>
                <Container id="general">
                    <Row className="justify-content-center pt-5 border-bottom mb-5">
                        <Col xs="12" className="text-center">
                            <div className="section-title mb-4 pb-2">
                                <h4 className="title mb-4" name="sectiontitle"> NUESTRA PROPUESTA </h4>
                                <p className=" mx-auto mb-0 text-justify">
                                    Somos especialistas en el proceso de estudio, instalación y puesta en marcha de proyectos de climatización mediante la elección de equipos técnicamente eficientes. Contamos con diversas soluciones para el área residencial y corporativa que requieran de control térmico y ambiental de recintos, tanto para la comodidad de las personas, como para procesos industriales.
                                </p>
                                <p className=" mx-auto mb-0 text-justify">

                                    Disponemos de equipos eficientes energéticamente, los que se pueden indexar a una red wifi para ser integrables a un software de domótica.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <section className="section pt-0" id="aire-acondicionado">
                    <Container>
                        <Row className="align-items-center reorder-items">
                            <Col lg="6" md="6" xs="12">
                                <Slider className="slides" {...settings}>
                                    {
                                        this.state.items.aire.images.map((item, key) =>
                                            <div key={key} className="d-flex align-items-center bg-half-260" style={{
                                                backgroundImage: `url(${item})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: 'cover',
                                                backgroundPositionY: 'center'
                                            }} />
                                        )
                                    }
                                </Slider>
                            </Col>
                            <Col lg="6" md="6" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-4">
                                    <h4 className="title mb-4"><span className="text-primary">Aire Acondicionado</span></h4>
                                    <p className="">
                                        Dentro de esta categoría se encuentran los siguientes equipos:
                                    </p>
                                    <ul className="list-unstyled feature-list mb-0 ">
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Multisplit Free Match.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Split Muro.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Split Muro Virus Protect.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Split Piso Cielo.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Split Cassette.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Split Ducto.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Equipos Ventana.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Accesorios.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'screen' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Sistemas de Control.</span>
                                        </li>
                                    </ul>

                                    <div className="mt-4 mb-5">
                                        <Link to="/contactanos?service=aire-acondicionado" className="btn-hover-cotiza2 mt-4 p-2">Cotiza Aquí</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section pt-0" id="calefaccion">
                    <Container>
                        <Row className="align-items-center reorder-items">
                            <Col lg="6" md="6" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-4">
                                    <h4 className="title mb-4"><span className="text-primary">Calefacción</span></h4>
                                    <p className="">
                                        Dentro de esta categoría se encuentran los siguientes equipos:
                                    </p>
                                    <ul className="list-unstyled feature-list mb-0 ">
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Calderas Murales.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Calderas de Pie.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Calefón.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Termostatos.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Acumuladores.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Radiadores.</span>
                                        </li>
                                    </ul>

                                    <div className="mt-4 mb-5">
                                        <Link to="/contactanos?service=calefaccion" className="btn-hover-cotiza2 mt-4 p-2">Cotiza Aquí</Link>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6" md="6" xs="12">
                                <Slider className="slides" {...settings}>
                                    {
                                        this.state.items.calefaccion.images.map((item, key) =>
                                            <div key={key} className="d-flex align-items-center bg-half-260" style={{
                                                backgroundImage: `url(${item})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: 'cover',
                                                backgroundPositionY: 'center'
                                            }} />
                                        )
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section pt-0" id="eficiencia-energetica">
                    <Container>
                        <Row className="align-items-center reorder-items">
                            <Col lg="6" md="6" xs="12">
                                <Slider className="slides" {...settings}>
                                    {
                                        this.state.items.eficiencia.images.map((item, key) =>
                                            <div key={key} className="d-flex align-items-center bg-half-260" style={{
                                                backgroundImage: `url(${item})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: 'cover',
                                                backgroundPositionY: 'center'
                                            }} />
                                        )
                                    }
                                </Slider>
                            </Col>

                            <Col lg="6" md="6" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-4">
                                    <h4 className="title mb-4"><span className="text-primary">Eficiencia Energética</span></h4>
                                    <p className="">
                                        Dentro de esta categoría se encuentran los siguientes equipos:
                                    </p>
                                    <ul className="list-unstyled feature-list mb-0 ">
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Energía Solar Térmica.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Pantallas Radiantes.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Bombas de Calor.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Medidores de Energía.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Termos Eléctricos.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Paneles Eléctricos.</span>
                                        </li>

                                    </ul>

                                    <div className="mt-4 mb-5">
                                        <Link to="/contactanos?service=eficiencia-energetica" className="btn-hover-cotiza2 mt-4 p-2">Cotiza Aquí</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section pt-0" id="purificacion-de-aire">
                    <Container>
                        <Row className="align-items-center reorder-items">
                            <Col lg="6" md="6" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-4">
                                    <h4 className="title mb-4"><span className="text-primary">Purificación de Aire</span></h4>
                                    <p className="">
                                        Dentro de esta categoría se encuentran los siguientes equipos:
                                    </p>
                                    <ul className="list-unstyled feature-list mb-0 ">
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Purificador de Aire.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Mediciones de calidad.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Eliminación Covid.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Tecnología Filtro Ion.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Tecnología Oxidación.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Tecnología Luz Ultrav.</span>
                                        </li>
                                        <li className="d-flex" onClick={(event) => {event.preventDefault(); this.setState({ isOpen: true, photoIndex : 0, type: 'blackout' })}} style={{cursor: 'pointer'}}>
                                            <i><FeatherIcon icon="chevron-right" className="fea icon-sm text-success mr-2" /></i>
                                            <span>Tecnología Filtro L.</span>
                                        </li>
                                    </ul>

                                    <div className="mt-4 mb-5">
                                        <Link to="/contactanos?service=purificacion-de-aire" className="btn-hover-cotiza2 mt-4 p-2">Cotiza Aquí</Link>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6" md="6" xs="12">
                                <Slider className="slides" {...settings}>
                                    {
                                        this.state.items.purificacion.images.map((item, key) =>
                                            <div key={key} className="d-flex align-items-center bg-half-260" style={{
                                                backgroundImage: `url(${item})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: 'cover',
                                                backgroundPositionY: 'center'
                                            }} />
                                        )
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </React.Fragment>
        );
    }
}

export default ClimatizacionDetailSection;