/* eslint-disable */
import axios from 'axios';


export const sendPost = async (body) => {
	return await axios.post(`https://api.solucionactiva.cl/contact-us`, body)
		.then((res) => {
			return {
				...res.data,
				...{
					status: res.status
				}
			}
		}).catch((error) => {
			return error?.response?.data;
		});
}