import React from 'react';
import ClimatizacionGroup from "./pages/Multiservicios/Climatizacion/ClimatizacionGroup";

//Multiservicios
const Multiservicios = React.lazy(() => import('./pages/Multiservicios/Index'));
const CortinasRollerGroup = React.lazy(() => import('./pages/Multiservicios/CortinasRoller/CortinasRollerGroup'));
const MallasDeProteccionGroup = React.lazy(() => import('./pages/Multiservicios/MallasDeProteccion/MallasDeProteccionGroup'));
const IntegracionGroup = React.lazy(() => import('./pages/Multiservicios/Integracion/IntegracionGroup'));
const CercoElectricoGroup = React.lazy(() => import('./pages/Multiservicios/CercoElectrico/CercoElectricoGroup'));
const ContactFullPage =  React.lazy(() => import('./pages/Multiservicios/Index/ContactFullPage'));



const routes = [
    //Multiservicios
    { path: '/cortinas-roller', component: CortinasRollerGroup },
    { path: '/mallas-de-proteccion', component: MallasDeProteccionGroup },
    { path: '/climatizacion', component: ClimatizacionGroup },
    { path: '/integracion', component: IntegracionGroup },
    { path: '/remodelacion', component: CercoElectricoGroup },
    { path: '/contactanos', component: ContactFullPage },

    //Index Main
    { path: '/index', component: Multiservicios },

    //Index root
    
    { path: '/', component: Multiservicios, isWithoutLayout : false, exact : true },
    { component: Multiservicios, isWithoutLayout : true, exact : false },
    
];

export default routes;