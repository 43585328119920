// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

//Import Slick Slider
import Slider from "react-slick";

//Import Slick Slider CSS
import "../../../assets/css/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

// import images
import aire1 from '../../../assets/images/multiservicios/climatizacion/aire1.jpg';
import calefaccion1 from '../../../assets/images/multiservicios/climatizacion/calefaccion1.jpg';
import eficiencia1 from '../../../assets/images/multiservicios/climatizacion/eficiencia1.jpg';
import purificacion1 from '../../../assets/images/multiservicios/climatizacion/purificacion1.jpg';


class ClimatizacionHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items : [
                { image : aire1, title : "Climatización"},
                { image : calefaccion1, title : "Climatización"},
                { image : eficiencia1, title : "Climatización"},
                { image : purificacion1, title : "Climatización"},
            ],
        }
    }

    componentDidMount(){
        const e1 = document.getElementsByClassName("slick-slide");
        this.state.items.map((item, i) => {
            e1[i].style.backgroundImage = `url(${item.image})`;
            e1[i].style.backgroundRepeat = 'no-repeat';
            e1[i].style.backgroundSize = 'cover';
            e1[i].style.backgroundPositionY = 'center';
            e1[i].style.backgroundPositionX = 'center';
        });
    }

    render() {
        var settings = {
            autoplay: true,
            infinite: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade : true,
            draggable : true,
            pauseOnHover: true
          };
        return (
            <React.Fragment>

                <section className="main-slider" id="top-general">
                    <Slider className="slides" {...settings}>
                        {
                            this.state.items.map((item, key) =>
                                <li key={key} className="d-flex align-items-center bg-half-260" style={{
                                    backgroundImage: `url(${item.image})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: 'cover',
                                    backgroundPositionY: 'center',
                                    backgroundPositionX: 'center'
                                }}>
                                    <div className="bg-overlay"></div>
                                    <Container>
                                        <Row className="justify-content-center">
                                            <Col xs="12" className="text-center">
                                                <div className="title-heading text-white mt-4">
                                                    <h1 className="display-4 title-dark font-weight-bold mb-3">{item.title}</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </li>
                            )
                        }
                    </Slider>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ClimatizacionHeader;