// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col, Alert, Form, FormGroup, Button, Input, Label, Card, CardBody } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//import images
import contactUs from '../../../assets/images/multiservicios/contact-us3.jpg';
import {sendPost} from "../../../helpers/apiHelper";

class ContactFormMultiservicios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error : false,
            success : false,
            isOpen: false,
            values: {
                name: '',
                mail: '',
                message: '',
                phoneNumber: '',
            },
            loading: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    handleChange(event, type) {
        this.setState({
            values: {
                ...this.state.values,
                ...{
                    [type]: event.target.value
                }
            }
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const response = await sendPost(this.state.values);
        if(!response.error) {
            this.setState({success : true, error: false});
            this.setState({
                values: {
                    name: '',
                    mail: '',
                    message: '',
                    phoneNumber: '',
                }
            });
        } else {
            this.setState({success : false, error: true});
        }

        this.setState({ loading: false });
    }

    openModal() {
        this.setState({ isOpen: true });
    }


    render() {
        return (
            <React.Fragment>
                <section className="section" style={{
                    backgroundImage: `url(${contactUs})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    backgroundPositionY: 'center',
                    backgroundPositionX: 'center'
                }} id="contact-form">
                <div className="bg-overlay"></div>
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="8" md="7">
                                <div className="section-title mr-lg-4">
                                    <h4 className="title title-dark text-light mb-4">¡Cuéntanos de tu proyecto!</h4>
                                    <p className="text-light para-dark para-desc mb-0">Empieza a trabajar con <span className="text-green-ms font-weight-bold">Nosotros,</span> estudiamos tu requerimiento de la manera más rápida y eficiente.</p>
                                </div>
                            </Col>

                            <Col lg="4" md="5" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <Card className="rounded shadow border-0">
                                    <CardBody>
                                    <h5 className="text-center">Escríbenos</h5>
                                    
                                        <Alert color="danger" isOpen={this.state.error} toggle={()=>{ this.setState({error : !this.state.error}) }}>
                                            ¡Hubo un error, intenta nuevamente!
                                        </Alert>

                                        <Alert color="success" isOpen={this.state.success} toggle={()=>{ this.setState({success : !this.state.success}) }}>
                                            ¡Hemos recibido tu mensaje!
                                        </Alert>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row className="mt-4">
                                            <Col lg="12">
                                                <FormGroup className="mb-3 position-relative">
                                                    <Label>Tu Nombre <span className="text-danger">*</span></Label>
                                                    <FeatherIcon icon="user" className="fea icon-sm icons" />
                                                    <Input name="name" id="name3" type="text"
                                                           className="form-control pl-5"
                                                           placeholder="Nombre"
                                                           required
                                                           onChange={(e) => this.handleChange(e, 'name')}
                                                           value={this.state.values.name} />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="12">
                                                <FormGroup className="mb-3 position-relative">
                                                    <Label>Tu Email <span className="text-danger">*</span></Label>
                                                    <FeatherIcon icon="mail" className="fea icon-sm icons" />
                                                    <Input type="email" id="email3" className="form-control pl-5" placeholder="Email" name="email" required onChange={(e) => this.handleChange(e, 'mail')} value={this.state.values.mail} />
                                                </FormGroup>
                                            </Col>

                                            <Col md="12">
                                                <FormGroup className="position-relative">
                                                    <Label>Tu Número :<span className="text-danger">*</span></Label>
                                                    <FeatherIcon icon="phone" className="fea icon-sm icons" />
                                                    <Input type="number" className="form-control pl-5" placeholder="56911111111" name="phoneNumber" required onChange={(e) => this.handleChange(e, 'phoneNumber')} value={this.state.values.phoneNumber} />
                                                </FormGroup>
                                            </Col>

                                            <Col md="12">
                                                <FormGroup className="position-relative">
                                                    <Label>Tu Mensaje :<span className="text-danger">*</span></Label>
                                                    <FeatherIcon icon="message-circle" className="fea icon-sm icons" />
                                                    <Input type="textarea" id="textarea" className="form-control pl-5" placeholder="Mensaje" name="mensaje" required onChange={(e) => this.handleChange(e, 'message')} value={this.state.values.message} />
                                                </FormGroup>
                                            </Col>

                                            <Col lg="12" className="mb-0">
                                                <Button color="primary" block disabled={this.state.loading}>
                                                    {
                                                        this.state.loading ? 'Enviando...' : 'Enviar Mensaje'
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-contact-form">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactFormMultiservicios;