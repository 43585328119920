// React Basic and Bootstrap
import React, { Component } from 'react';

// Import Generic components
import ClimatizacionDetailSection from './ClimatizacionDetailSection';
import ClimatizacionHeader from './ClimatizacionHeader';

//Import Images
import ContactForm from "../Index/ContactForm";

class ClimatizacionGroup extends Component {

    render() {

        return (
            <React.Fragment>

                <ClimatizacionHeader />

                <ClimatizacionDetailSection />

                <ContactForm />

            </React.Fragment>
        );
    }
}

export default ClimatizacionGroup;
